import * as React from "react";
import { Link } from "gatsby";
import { Layout } from "../components";

const NotFoundPage = () => {
  return (
    <Layout>
      <title className="title">Not found</title>
      <h2 className="subtitle">Page not found</h2>
      <div className="content">
        <p>
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
